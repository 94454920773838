export default [
    {
        block_name: 'default',
        menu: {
            dashboard: {
                title: 'Dashboard',
                to: {name: 'dashboard'},
                icon: 'dashboard',
            },
        },
    },
    {
        block_name: 'sites',
        block_title: 'SITES',
        menu: {
            site_list: {
                title: 'Site List',
                to: {name: 'site-lists'},
                icon: 'file-text',
            },
            sites_map: {
                title: 'Sites Map',
                to: {name: 'sites-map'},
                icon: 'map',
            },
        }
    },
    {
        block_name: 'configuration',
        block_title: 'CONFIGURATION',
        menu: {
            qms_templates: {
                title: 'QMS Templates',
                to: {name: 'qms-templates'},
                icon: 'templates',
            },
            users: {
                title: 'Users',
                to: {name: 'users'},
                icon: 'users',
            },
        },
    }

]
