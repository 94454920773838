<template>
    <div class="layout" :class="{'layout--navigation': is_navigation}">
        <app-loader v-if="loader"/>

        <transition name="navigation" @after-enter="dispatchResize" @after-leave="dispatchResize">
            <aside v-if="is_navigation" class="layout__aside">
                <app-navigation class="layout__navigation" />
            </aside>
        </transition>

        <div class="layout__wrapper">
            <app-header
                class="layout__header"
                :is_navigation="is_navigation"
                @toggleNavigation="toggleNavigation"
            />

            <main class="layout__main">
                <router-view class="page"/>
            </main>

            <app-footer class="layout__footer"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppLoader from '@/components/app-loader'
import AppHeader from '@/components/app-header'
import AppFooter from '@/components/app-footer'
import AppNavigation from '@/components/app-navigation'

export default {
    components: {
        AppLoader,
        AppHeader,
        AppFooter,
        AppNavigation,
    },
    data() {
        return {
            loader: false,

            is_navigation: true,
        }
    },
    computed: {
        ...mapGetters(['is_tablet_style']),
    },
    methods: {
        toggleNavigation() {
            this.is_navigation = !this.is_navigation
        },
        dispatchResize() {
            this.$nextTick(() => {
                window.dispatchEvent(new Event('resize'))
            })
        }
    },
    beforeMount() {
        this.is_navigation = !this.is_tablet_style
    },
}
</script>

<style lang="scss" scoped>
.layout {
    $root: &;

    flex: 1 1 100%;
    max-width: 100%;

    display: flex;

    position: relative;

    &__aside {
        display: flex;
        justify-content: flex-end;

        flex: 0 0 auto;
        width: $navigation-bar-width;
        overflow: hidden;

        z-index: $z-index-sidebar;

        @include media-bp(tab) {
            position: absolute;
            top: 0;
            bottom: 0;
        }
    }

    &__wrapper {
        flex: 1 1 auto;
        max-width: calc( 100% );

        transition: all $transition-duration-primary;

        #{$root}--navigation & {
            max-width: calc( 100% - #{$navigation-bar-width} );

            @include media-bp(tab) {
                max-width: calc( 100% );
            }
        }

        display: flex;

        flex-direction: column;
    }

    &__main {
        flex: 1 1 auto;
        max-width: 100%;

        display: flex;

        padding: 24px 15px 48px;
    }
}

.navigation {
    &-enter-active,
    &-leave-active {
        transition: all $transition-duration-primary;
    }

    &-enter,
    &-leave-to {
        width: 0;
    }
}

</style>