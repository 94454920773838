<template>
    <div class="notifier">
        <span class="notifier__icon icon" :class="`icon--${icon}`" />
        <span v-if="count" class="notifier__badge">{{count}}</span>
    </div>
</template>

<script>
export default {
    props: ['icon', 'count']
}
</script>

<style lang="scss" scoped>
.notifier {
    position: relative;
    margin: 12px;

    cursor: pointer;

    &__icon {
        font-size: 24px;
    }

    &__badge {
        position: absolute;
        top: -6px;
        left: calc( 100% - 6px);

        display: block;
        padding: 1px 3px;
        border-radius: 4px;
        height: 12px;

        font-size: 10px;
        line-height: 1.2;
        color: $white;
        background-color: $brand-color-primary;
    }
}
</style>