<template>
    <header class="header" :class="{'header--navigation': is_navigation}">

        <div class="header__navigation">
            <a class="toggle-navigation" @click.prevent="toggleNavigation">
                <img class="toggle-navigation__image" src="./img/menu.svg" alt="toggle">
                <span class="toggle-navigation__text">Downer | QMS</span>
            </a>
        </div>

        <div class="header__account">
            <div class="header__notifiers">
                <app-notifier
                    v-for="(count, notifier) in notifiers"
                    :key="`notifier-${notifier}`"
                    class="header__notifier"
                    :class="`header__notifier--${notifier}`"
                    :icon="notifier"
                    :count="count"
                />
            </div>

            <app-account-menu class="header__account-menu" />
        </div>

    </header>
</template>

<script>
import AppNotifier from '@/components/app-notifier'
import AppAccountMenu from '@/components/app-account-menu'

import { mapGetters } from 'vuex'

export default {
    props: ['is_navigation'],
    components: {
        AppNotifier,
        AppAccountMenu,
    },
    data() {
        return {
            notifiers: {
                users: 2,
                target: 58,
                flag: 8,
                bell: null,
            }
        }
    },
    methods: {
        toggleNavigation() {
            this.$emit('toggleNavigation')
        }
    },
}
</script>

<style lang="scss">
.header {
    $root: &;

    height: $header-height;

    padding: 16px;

    background: $white;
    box-shadow: 0 2px 12px 0 rgba($black, 0.1);

    display: flex;

    justify-content: space-between;
    align-items: center;

    z-index: $z-index-header;

    &__navigation {
        transition: all $transition-duration-primary;

        @include media-bp(tab) {
            margin-left: 0px;

            #{$root}--navigation & {
                margin-left: $navigation-bar-width;
            }
        }
    }

    &__account {
        display: flex;
    }

    &__notifiers {
        display: flex;
        align-items: center;

        margin-top: 6px;

        color: $brand-color-secondary-dark;
    }
}

.toggle-navigation {
    font-size: 24px;
    line-height: 1.33;
    font-weight: 600;

    color: $brand-color-secondary-dark;

    cursor: pointer;

    display: flex;
    align-items: center;

    &__text {
        margin: 4px 0 0 24px;
    }
}
</style>