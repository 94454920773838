<template>
    <div class="menu">
        <div class="menu__block" v-for="({block_name, block_title, menu}, i) in menu_items" :key="`block-${i}-${block_name}`">
            <span v-if="block_title" class="menu__block-title">{{block_title}}</span>

            <div class="menu__container">
                <div
                    v-for="(menu_item, menu_item_name) in menu"
                    :key="`block-${i}-${block_name}-item-${menu_item_name}`"
                    class="menu__item"
                    :class="{'menu__item--expanded': isMenuItemExpanded(menu_item_name)}"
                >
                    <template v-if="menu_item.to">
                        <router-link
                            class="menu__item-link"
                            active-class="menu__item-link--active"
                            :to="menu_item.to"
                        >
                            <span class="menu__item-icon icon" :class="`icon--${menu_item.icon || 'folder'}`" />
                            <span class="menu__item-title">{{menu_item.title}}</span>
                        </router-link>
                    </template>

                    <template v-else-if="menu_item.submenu">
                        <div class="menu__item-link menu__item-link--submenu" @click="toggleSubMenu(menu_item_name)">
                            <span class="menu__item-icon icon" :class="`icon--${menu_item.icon || 'folder'}`" />
                            <span class="menu__item-title">{{menu_item.title}}</span>
                        </div>

                        <div class="menu__item-subcontainer">
                            <div
                                class="menu__subitem"
                                v-for="(menu_subitem, menu_subitem_name) in menu_item.submenu"
                                :key="`block-${i}-${block_name}-item-${menu_item_name}-subitem-${menu_subitem_name}`"
                            >
                                <router-link
                                    class="menu__subitem-link"
                                    active-class="menu__subitem-link--active"
                                    :to="menu_subitem.to"
                                >
                                    <span class="menu__subitem-title">{{menu_subitem.title}}</span>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MENU from './menu_items.js'

export default {
    data() {
        return {
            menu_items: MENU,

            menu_item_expanded: null,


        }
    },
    computed: {
        active_menu_item() {
            const route_name = this.$route.name

            for (let i = 0; i < this.menu_items.length; i++) {
                const { menu } = this.menu_items[i]

                for (const menu_item_name in menu) {
                    const { to, submenu } = menu[menu_item_name]

                    if (to && to.name === route_name) {
                        return menu_item_name
                    }

                    if (submenu instanceof Object ) {
                        if (Object.values(submenu).some(({ to }) => to.name && to.name == route_name)) {
                            return menu_item_name
                        }
                    }
                }
            }

            return null
        }
    },
    methods: {
        isMenuItemExpanded(menu_item_name) {
            return menu_item_name === this.menu_item_expanded || menu_item_name === this.active_menu_item
        },

        toggleSubMenu(menu_item_name) {
            if (this.menu_item_expanded === menu_item_name) {
                this.menu_item_expanded = null
            } else {
                this.menu_item_expanded = menu_item_name
            }

        },
    },
}
</script>

<style lang="scss" scoped>
.menu {
    &__block {
        margin-top: 30px;

        &-title {
            color: $white;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 600;
        }
    }

    &__item {
        margin-top: 10px;

        &--expanded,
        &:hover {
            background-color: $gray-dark;
        }

        &-link {
            display: flex;
            align-items: center;

            position: relative;
            padding-top: 4px;
            padding-left: 40px;
            padding-right: 8px;

            box-sizing: border-box;
            width: 100%;
            height: 48px;

            color: $white;
            font-size: 20px;
            line-height: 1.2;

            border: 1px solid transparent;

            cursor: pointer;
            user-select: none;

            &:hover {
                color: $brand-color-primary;
            }

            &--active {
                color: $brand-color-primary;
                background-color: $gray-dark;
                // border-bottom-color: $brand-color-primary;
            }


            &--submenu {
                @include icon('chevron-right', 'after');

                padding-right: 32px;
            }

            &::after {
                position: absolute;
                top: 12px;
                right: 8px;
                font-size: 24px;
            }
        }

        &-icon {
            position: absolute;
            top: 12px;
            left: 8px;
            font-size: 24px;
        }

        &-title {
            display: inline-block;

            margin-top: 4px;
            max-width: 100%;

            @include text-overflow();
        }

        &-subcontainer {
            display: none;
            padding: 6px 8px;
        }

        &--expanded &-subcontainer {
            display: block;
        }

        &--expanded &-link--submenu {
            @include icon('chevron-down', 'after');
        }
    }


    &__subitem {
        &-link {
            display: block;
            font-size: 18px;
            line-height: 1.33;

            margin-top: 8px;

            color: $white;

            border-bottom: 1px solid transparent;

            &:hover {
                color: $brand-color-primary;
            }

            &--active {
                color: $brand-color-primary;
                border-color: $brand-color-primary;
            }

        }
    }
}
</style>