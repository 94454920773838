<template>
    <app-dropdown
        class="account-menu"
        ref="dropdown"
        body-max-height="auto"
        header-padding-right="13px"
    >

        <template #header>
            <div class="account-menu__user">
                <div class="account-menu__user-avatar">
                    <img
                        v-if="user_photo"
                        class="account-menu__user-photo"
                        :src="user_photo"
                        alt="User Photo"
                    />
                </div>
                <span class="account-menu__user-name">{{ user_display_name }}</span>
            </div>
        </template>

        <template #body>
            <div class="account-menu__body">
                <div class="account-menu__info">
                    <div v-if="user_email" class="account-menu__info-line"><span>{{ user_email }}</span></div>
                    <div v-if="user_phone" class="account-menu__info-line"><span>{{ user_phone }}</span></div>
                </div>

                <div class="account-menu__actions"> 
                    <router-link class="settings" :to="{ name: 'settings' }">Settings</router-link>
                    <button @click="changePassword" :disabled="processing">Change password</button>
                    <button @click="logout" :disabled="processing">Sign out</button>
                </div>
            </div>
        </template>

    </app-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

import AppDropdown from '@/components/app-dropdown'

export default {
    components: {
        AppDropdown,
    },
    data() {
        return {
            processing: false,
            user_photo: '',
        }
    },

    computed: {
        ...mapGetters({
            user_display_name: 'id_display_name',
            user_email: 'id_email',
            user_phone: 'id_phone',
        }),
    },

    methods: {
        logout() {
            this.$store.dispatch('Logout')
            this.$router.push({name: 'sign-in'})
        },

        changePassword() {
            //TODO change pass dialog
            this.$refs.dropdown.close()
        },
    },
}
</script>

<style lang="scss">
.account-menu {
    $root: &;

    display: flex;
    align-items: center;

    color: $black;
    background-color: $white;

    margin-left: 12px;

    .dropdown {
        &__trigger {
            border-radius: 24px;

            &:hover {
                background-color: rgba($black, 0.1);
            }

            @include media-bp(tab) {
                &:hover {
                    background-color: inherit;
                }

                &::after {
                    right: 0;
                }
            }
        }

        &__body {
            left: auto;
            width: 300px;
        }
    }

    &__user {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        font-size: 20px;
        line-height: 1.2;
        color: $black;

        &-avatar {
            flex-shrink: 0;
            width: 48px;
            height: 48px;
            background: url('./img/account.svg') no-repeat center;
            background-size: cover;
            border-radius: 24px;
            overflow: hidden;
        }

        &-photo {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-name {
            margin-top: 4px;
            margin-left: 16px;
            margin-right: 4px;

            @include text-overflow();

            @include media-bp(tab) {
                display: none;
            }
        }
    }

    &__body {
        padding: 0 24px;
    }

    &__info {
        padding: 12px 0;

        color: $text-color-secondary;

        &-line {
            margin: 6px 0;

            &:first-child { margin-top: 0; }
            &:last-child { margin-bottom: 0; }
        }
    }

    &__actions {
        padding: 12px 0;
        border-top: 1px solid $gray;

        a, button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-right: 8px + 16px;
            padding: 4px 4px 0;
            margin: 6px 0;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            white-space: nowrap;
            cursor: pointer;

            &:first-child { margin-top: 0; }
            &:last-child { margin-bottom: 0; }

            &:hover {
                background-color: rgba($black, 0.1);
            }
        }
    }
}
</style>