<template>
    <div class="navigation">
        <app-logo class="navigation__logo"/>

        <div class="navigation__container">
            <div class="navigation__scroll">

                <Menu class="navigation__menu" />

            </div>
        </div>
    </div>
</template>

<script>
import AppLogo from '@/components/app-logo'
import Menu from './menu.vue'

export default {
    components: {
        AppLogo,
        Menu,
    }
}
</script>

<style lang="scss" scoped>
.navigation {
    flex: 1 0 $navigation-bar-width;
    width: $navigation-bar-width;

    display: flex;
    flex-direction: column;

    color: $white;

    background: $black;

    &__logo {
        width: 130px;
        margin: 16px auto;
    }

    &__menu {
        margin: 16px;
    }
}
</style>