<template>
    <footer class="footer">
        {{copyright}}
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        copyright() {
            return process.env.VUE_APP_COPYRIGHT
        }
    },
}
</script>

<style lang="scss">
.footer{
    height: $footer-height;

    background: $gray;
    color: $white;
    font-size: 24px;
    line-height: 1.33;

    padding: 28px 24px 20px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>